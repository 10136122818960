<template>
  <div class="card">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.card {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 2em 3em;
  border-radius: 1.2em;
  box-shadow: var(--box-shadow-default);
  margin: 10px auto 0 auto;
  @media only screen and (max-width: 720px) {
    padding: 2em 2em !important;
  }
}
</style>
