<script setup>
	import { ref, inject, onMounted } from "vue";
	import { useRouter } from "vue-router";
	import http from "@/http";

	import Header from "../baseComponents/Header.vue";
	import PwdIcon from "vue-material-design-icons/LockOutline";
	import ArrowIcon from "vue-material-design-icons/ChevronRight";
	import EmailOutline from "vue-material-design-icons/EmailOutline.vue";

	const router = useRouter();
	const swal = inject("$swal");
	const loading = ref(false);
	const user = ref({ id: null, password: "", password2: "" });
	const userCode = ref({ code: null, email: "" });
	const routeParam = router?.currentRoute?.value?.params?.id;

	onMounted(() => {
		if (routeParam === "codigo") {
			userCode.value.email = localStorage.emailValidationCode;
		} else user.value.id = routeParam;
	});

	const action = () => {
		if (loading.value) return;

		routeParam === "codigo" ? checkConfirmationCod() : setPassword();
	};

	const checkConfirmationCod = () => {
		if (!userCode.value.code) {
			return fieldRequiredAlert("Por favor, informe o código enviado por e-mail.");
		}

		loading.value = true;

		http
			.post(`user/authenticationCode/validate`, userCode.value)
			.then((response) => {
				localStorage.user = JSON.stringify(response.data);
				router.push({ path: "/home" });
				sucessAlert();
			})
			.catch((err) => {
				console.log(err);
				fieldRequiredAlert(
					"Parece que o código informado está incorreto. Verifique e tente novamente."
				);
			})
			.finally(() => {
				loading.value = false;
			});
	};

	const setPassword = () => {
		if (loading.value) return;

		if (!user.value.password) {
			return fieldRequiredAlert("Por favor, informe sua senha.");
		}

		if (!user.value.password2) {
			return fieldRequiredAlert("Por favor, confirme a sua nova senha.");
		}

		if (user.value.password != user.value.password2) {
			return fieldRequiredAlert(
				"Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente."
			);
		}

		loading.value = true;

		http
			.put(`user/firstAccess`, user.value)
			.then(() => {
				router.push({ path: "/login" });
				sucessAlert();
			})
			.catch((err) => {
				console.log(err);
				fieldRequiredAlert(
					"Algo deu errado, caso o erro persista entre em contato com o suporte."
				);
				loading.value = false;
			});
	};

	const sucessAlert = () => {
		swal.fire({
			toast: true,
			icon: "success",
			title:
				routeParam === "codigo"
					? "Código confirmado!"
					: "Senha cadastrada com sucesso!",
			position: "top-right",
			showConfirmButton: false,
			timer: 3500,
			timerProgressBar: true,
		});
	};
	const fieldRequiredAlert = (msg) => {
		swal.fire({
			position: "center",
			icon: "warning",
			title: "Ops...",
			text: msg,
			showConfirmButton: true,
		});
	};
</script>

<template>
	<div>
		<Header :passwordRecovery="true" />
		<div>
			<div class="main-holder desktop4 tablet6">
				<div class="title animated fadeInDown" style="animation-delay: 300ms">
					{{
						routeParam === "codigo"
							? "Enviamos um código para o seu e-mail"
							: "Cadastrar senha de acesso"
					}}
				</div>

				<div
					v-if="routeParam === 'codigo'"
					class="inputs-holder desktop10 mobile10"
				>
					<div class="input-item animated fadeInDown" style="animation-delay: 500ms">
						<EmailOutline :size="24" />
						<div class="input-holder">
							<input
								v-model="userCode.code"
								type="text"
								placeholder="Insira o código aqui"
								@keypress.enter="action"
							/>
						</div>
					</div>
				</div>

				<div v-else class="inputs-holder desktop10 mobile10">
					<div class="input-item animated fadeInDown" style="animation-delay: 500ms">
						<PwdIcon :size="24" />
						<div class="input-holder">
							<input v-model="user.password" type="password" placeholder="Senha" />
						</div>
					</div>
					<div class="input-item animated fadeInDown" style="animation-delay: 700ms">
						<PwdIcon :size="24" />
						<div class="input-holder">
							<input
								v-model="user.password2"
								type="password"
								placeholder="Confirme a sua senha"
								@keypress.enter="action"
							/>
						</div>
					</div>
				</div>

				<div class="flex mt-2 animated zoomIn" style="animation-delay: 900ms">
					<div @click="action" class="btn icon">
						<span v-show="!loading">Confirmar</span>
						<ArrowIcon v-show="!loading" :size="28" fillColor="#fff" />
						<div v-show="loading" class="loading white"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@media only screen and (min-width: 1160px) {
		.main-holder {
			background-color: #fff;
			padding: 2em;
			border-radius: 1.2em;
			box-shadow: var(--box-shadow-default);
		}
	}

	.title {
		position: relative;
		width: fit-content;
		font-size: 1.3em;
		color: var(--primary);
	}

	.input-item {
		position: relative;
		input {
			padding: 0 50px 0 50px !important;
		}
		span {
			position: absolute;
			bottom: 4px;
			left: 15px;
			z-index: 2;
		}
	}
</style>
