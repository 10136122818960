<script setup>
import { ref, inject } from "vue";
import http from "@/http";
import validators from "../util/validators";
import authentication from "../util/authentication";

import Header from "../baseComponents/Header.vue";
import Footer from "../baseComponents/Footer.vue";
import Card from "../baseComponents/Card.vue";

const swal = inject("$swal");
const loading = ref(true);
const savingUser = ref(false);
const savingPassword = ref(false);

const user = ref({
  company: "",
  name: "",
  email: "",
  phoneNumber: "",
});

const userPassword = ref({
  password: "",
  newPassword: "",
  confirmationNewPassword: "",
});

http
  .get(`user/details?id=${authentication.getUser()?.info?.id}`)
  .then((response) => {
    user.value = response?.data;
  })
  .catch((err) => {
    console.log(err);
    swal.fire({
      icon: "error",
      text: "Por algum motivo não conseguimos carregar suas informações, caso o erro persista entre em contato com o suporte.",
    });
  })
  .finally(() => {
    loading.value = false;
  });

const updateUser = () => {
  if (savingUser.value) return;

  savingUser.value = true;

  if (!checkFormToUpdate("user")) {
    savingUser.value = false;
    return;
  }

  http
    .put("user", user.value)
    .then((response) => {
      user.value = response?.data;
      toast("Perfil atualizado com sucesso!");
    })
    .catch((err) => {
      console.log(err);
      this.$swal({
        icon: "warning",
        text: "Algo deu errado, caso o erro persista entre em contato com o suporte.",
      });
    })
    .finally(() => {
      savingUser.value = false;
    });
};

const updatePassword = () => {
  if (savingPassword.value) return;

  savingPassword.value = true;

  if (!checkFormToUpdate("password")) {
    fieldRequiredAlert(
      "Por favor, verifique se os campos obrigatórios(*) estão preenchidos corretamente."
    );
    savingPassword.value = false;
    return;
  }

  http
    .put("User/ChangePassword", userPassword.value)
    .then(() => {
      toast("Senha atualizada com sucesso!");
      userPassword.value.password = "";
      userPassword.value.newPassword = "";
      userPassword.value.confirmationNewPassword = "";
    })
    .catch((err) => {
      console.log(err);
      swal.fire({
        icon: "warning",
        text: "Algo deu errado, caso o erro persista entre em contato com o suporte.",
      });
    })
    .finally(() => {
      savingPassword.value = false;
    });
};

const checkFormToUpdate = (type) => {
  if (type == "user" && !validators.validateFullName(user.value.name)) {
    fieldRequiredAlert(
      "Por favor, verifique se inseriu corretamente o nome completo."
    );
    return false;
  }

  if (type == "user" && !user.value.phoneNumber) {
    fieldRequiredAlert(
      "Por favor, verifique se inseriu corretamente o telefone."
    );
    return false;
  }

  if (
    type == "password" &&
    userPassword.value.newPassword !==
      userPassword.value.confirmationNewPassword
  ) {
    return false;
  }

  return true;
};

const toast = (text) => {
  swal.fire({
    toast: true,
    icon: "sucess",
    title: text,
    position: "top-right",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
  });
};

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    title: "Ops...",
    text: msg,
    showConfirmButton: true,
  });
};
</script>

<template>
  <div>
    <Header />

    <transition enter-active-class="animated zoomIn">
      <div v-show="loading" class="full-loading">
        <div class="loading"></div>
      </div>
    </transition>

    <div v-show="!loading" class="container mt-3 mt-1-mobile mb-3 mb-1-mobile">
      <Card
        class="desktop6 mr-1-desktop animated fadeInLeft"
        style="animation-delay: 400ms"
      >
        <div class="card-header">
          <img
            class="mobile-hidden"
            src="../assets/images/Icone_Cadastro.png"
            alt="Perfil"
          />
          <div class="title ml-0-mobile">Meus dados</div>
        </div>
        <div class="divider mt-2"></div>
        <div class="inputs-holder">
          <div class="input-item">
            <span>Nome *</span>
            <div class="input-holder">
              <input type="text" v-model="user.name" />
            </div>
          </div>
          <div class="input-item">
            <span>Telefone *</span>
            <div class="input-holder">
              <input
                @keypress.enter="updateUser"
                type="text"
                v-model="user.phoneNumber"
              />
            </div>
          </div>
          <div class="input-item">
            <span>Email</span>
            <div class="input-holder">
              <input disabled type="email" v-model="user.email" />
            </div>
          </div>
          <div class="input-item">
            <span>Empresa</span>
            <div class="input-holder">
              <input disabled type="text" v-model="user.company.name" />
            </div>
          </div>
        </div>
        <div class="mt-3 flex-center">
          <div @click="updateUser" class="btn">
            <span v-show="!savingUser">Atualizar dados</span>
            <div v-show="savingUser" class="loading white"></div>
          </div>
        </div>
      </Card>
      <Card
        class="desktop6 ml-1-desktop mt-3-tablet mt-3-mobile animated fadeInRight"
        style="animation-delay: 500ms"
      >
        <div class="card-header">
          <img
            class="mobile-hidden"
            src="../assets/images/Icone_Cadeado.png"
            alt="Perfil"
          />
          <div class="title ml-0-mobile">Alterar senha</div>
        </div>
        <div class="divider mt-2"></div>
        <div class="inputs-holder">
          <div class="input-item">
            <span>Senha atual *</span>
            <div class="input-holder">
              <input type="password" v-model="userPassword.password" />
            </div>
          </div>
        </div>
        <div class="inputs-holder">
          <div class="input-item">
            <span>Nova senha *</span>
            <div class="input-holder">
              <input type="password" v-model="userPassword.newPassword" />
            </div>
          </div>
          <div class="input-item">
            <span>Confirmar nova senha *</span>
            <div class="input-holder">
              <input
                type="password"
                v-model="userPassword.confirmationNewPassword"
              />
            </div>
          </div>
        </div>
        <div class="mt-3 flex-center">
          <div @click="updatePassword" class="btn">
            <span v-show="!savingPassword">Trocar senha</span>
            <div v-show="savingPassword" class="loading white"></div>
          </div>
        </div>
      </Card>
    </div>

    <Footer
      v-show="!loading"
      class="footer animated fadeInUp"
      style="animation-delay: 1000ms"
    />
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 721px) and (max-width: 1159px) {
    flex-direction: column;
    .mt-4-tablet {
      margin-top: 120px !important;
    }
  }
  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }
}

.card-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    position: absolute;
    top: -60px;
    object-fit: cover;
    width: 90px;
  }
  .title {
    margin-left: 140px;
  }
}

.footer {
  padding: 40px 0 0px 0;
}
</style>
