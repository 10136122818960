import axios from "axios";
import authentication from "@/util/authentication";

let endpoint = "https://apianpii.5p2r.com.br/api/";
// let endpoint = "http://35.171.128.230:97/api/";
if (process.env.NODE_ENV === "development") {
	endpoint = "https://localhost:44350/api/";
  // endpoint = "https://apianpii.5p2r.com.br/api/";
}

const http = axios.create({
	baseURL: endpoint,
});

http.interceptors.request.use(
	async (config) => {
		const accessToken = await authentication.getRefreshedToken();
		if (accessToken)
			config.headers = {
				Authorization: `Bearer ${accessToken}`,
				"Content-Type": "application/json",
			};
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

http.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			authentication.logout();
		}
		return Promise.reject(error);
	}
);

export default http;
