<script setup>
import { ref, inject, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import validators from "../util/validators";
import authentication from "../util/authentication";

import EmailIcon from "vue-material-design-icons/EmailOutline";
import PwdIcon from "vue-material-design-icons/LockOutline";
import ArrowIcon from "vue-material-design-icons/ChevronRight";

const router = useRouter();
const swal = inject("$swal");
const loading = ref(false);
const keepLogin = ref(false);

const user = ref({ email: "", password: "" });

watch(keepLogin, (newKeepLoginValue) => {
  localStorage.keepLogin = newKeepLoginValue;
});

onMounted(() => {
  if (localStorage.keepLogin === "true" && authentication.isLoggedIn())
    router.push({ path: "/home" });
});

const login = () => {
  if (loading.value) return;

  if (!validators.validateEmail(user.value.email)) {
    fieldRequiredAlert(
      "Por favor, verifique se o e-mail digitado está correto."
    );
    return;
  }

  if (user.value.password.length < 1) {
    fieldRequiredAlert("Por favor, informe sua senha.");
    return;
  }

  loading.value = true;

  authentication
    .login(user.value)
    .then((response) => {
      if (response === true) {
        router.push({ path: "/primeiro-acesso/codigo" });
      } else fieldRequiredAlert("E-mail e/ou senha incorretos.");
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert(
        "Algo deu errado, caso o erro persista entre em contato com o suporte."
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    title: "Ops...",
    text: msg,
    showConfirmButton: true,
  });
};
</script>
<template>
  <div class="all">
    <div class="img-holder tablet-hidden mobile-hidden">
      <img src="../assets/images/login-bg.jpg" alt="Imagem do campo" />
    </div>
    <div class="form">
      <div
        class="form-header animated fadeInDown"
        style="animation-delay: 250ms"
      >
        <span>Plataforma de Inteligência de Mercado</span>
      </div>
      <div class="form-holder">
        <div
          class="img-header animated fadeInDown"
          style="animation-delay: 400ms"
        >
          <img src="../assets/images/logo.svg" alt="Logo" />
        </div>
        <div class="card-form animated zoomIn" style="animation-delay: 550ms">
          <div class="welcome">
            <h1>Bem vindo!</h1>
            <span>Faça login abaixo para continuar</span>
          </div>
          <div class="input-icon">
            <EmailIcon fillColor="#707070" />
            <input v-model="user.email" type="email" placeholder="E-mail" />
          </div>
          <div class="input-icon">
            <PwdIcon fillColor="#707070" />
            <input
              @keypress.enter="login"
              v-model="user.password"
              type="password"
              placeholder="Senha"
            />
          </div>
          <div class="switch-holder">
            <div @click="keepLogin = !keepLogin" class="switch">
              <input
                type="checkbox"
                id="keepLogin"
                v-model="keepLogin"
                class="pointer"
              />
              <span class="slider round"></span>
            </div>
            <label class="pointer" for="keepLogin">Manter-me conectado</label>
          </div>
          <div @click="login" class="btn icon mb-2 mt-0 mobile12">
            <span v-show="!loading">Fazer login</span>
            <ArrowIcon v-show="!loading" :size="30" fillColor="#fff" />
            <div v-show="loading" class="loading white"></div>
          </div>
          <router-link to="/recuperar-senha" class="forgot-password">
            Esqueceu sua senha?
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.all {
  display: flex;
  position: relative;
  width: 100vw;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: stretch;
  background: linear-gradient(257.61deg, #f1ffea 0%, #ffffff 100%);
}

.form-holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2em;
}

.img-holder {
  width: 56%;
}

.form {
  width: 44%;
}

.img-holder,
.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 1159px) {
    width: 100%;
  }
  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .form-header {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.5em 0px;
    top: 0;
    left: 0;
    background-color: var(--primary);
    span {
      font-size: 1.5em;
      font-family: fontRegular;
      color: #fff;
    }
  }
  .img-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto !important;
    img {
      width: 200px;
      object-fit: cover;
      @media only screen and (max-width: 720px) {
        width: 45%;
      }
    }
  }
  .card-form {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
    width: 85%;
    max-width: 440px !important;
    padding: 3em 3.6em;
    box-shadow: var(--box-shadow-default);
    border-radius: 12px;
    margin: 20px auto 0 auto !important;
    @media only screen and (max-width: 720px) {
      padding: 1.5em;
    }
    .welcome {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      h1 {
        font-family: fontBold;
        color: var(--primary);
        font-size: 2em;
        margin-bottom: 10px;
      }
      span {
        font-family: fontLight;
      }
    }
    .input-icon {
      position: relative;
      height: 40px;
      width: 100%;
      background: #eee;
      border-radius: 6px;
      padding: 10px 14px;
      margin-bottom: 20px;
      .material-design-icon {
        position: absolute;
        top: 8px;
        z-index: 1;
        pointer-events: none;
      }
      input {
        position: relative;
        width: 100%;
        padding-left: 38px;
        height: 100%;
        z-index: 0;
        background: transparent;
        &::placeholder {
          font-family: fontLight;
          color: #707070;
        }
      }
    }
    .switch-holder {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 44px;
      height: 20px;
    }
    .switch input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
    }
    .forgot-password {
      position: relative;
      width: fit-content;
      cursor: pointer;
      font-family: fontLight;
      margin: 0 auto;
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 8px;
  right: 5px;
  bottom: 2px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 1;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 5px;
  bottom: 3.2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e2e2e2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e2e2e2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  background-color: var(--primary);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
