import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
import vClickOutside from "click-outside-vue3";
import moment from "moment";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/css/desktop.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";
import "./assets/css/animate.css";

const app = createApp(App);

const options = {
  confirmButtonColor: "var(--primary)",
  cancelButtonColor: "#aaa",
  imageHeight: "90px",
  showClass: {
    popup: "animated zoomIn",
  },
  customClass: {
    title: "page-title",
    popup: "text-desc",
  },
};

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});
app.config.globalProperties.$filters = {
  dateFilter(date) {
    return moment(date).format("DD/MM/YYYY");
  },
  currencyFilter(value) {
    return formatter.format(value);
  },
};

app.use(router);
app.use(VueSweetalert2, options);
app.use(vClickOutside);
app.mount("#app");
