<script setup>
import { ref, inject, defineEmits } from "vue";

import http from "@/http";

import Card from "@/baseComponents/Card";
import DownloadIcon from "vue-material-design-icons/TrayArrowDown";
import ArrowIcon from "vue-material-design-icons/ChevronDown";
import UploadIcon from "vue-material-design-icons/CloudUploadOutline";

const emit = defineEmits(["reportSentSuccessfully"]);
const loading = ref(true);
const loadingImportSales = ref(false);
const periods = ref([]);
const selectedFile = ref(null);
const selectedPeriod = ref(null);
const file = ref(null);
const defaultSheet = ref("");

http
 .get("period/list?orderBy=name&take=-1&reportsNotSent=true")
 .then((response) => {
  periods.value = response?.data?.list;
 })
 .catch((err) => {
  console.log(err);
 })
 .finally(() => {
  loading.value = false;
 });

http
 .get("user/getDefaultSheet")
 .then((response) => {
  defaultSheet.value = response?.data;
 })
 .catch((err) => {
  console.log(err);
 })
 .finally(() => {
  loading.value = false;
 });

const setFile = (event) => {
 if (!selectedPeriod.value?.id) {
  swal.fire({
   icon: "warning",
   title: "Período das vendas",
   text: "por favor, escolha um período!",
  });
  file.value = null;
  return;
 }

 file.value = event.target.files[0];

 importSales(file.value);
};

const importSales = (objFile) => {
 if (!objFile) return;

 loadingImportSales.value = true;

 const formData = new FormData();
 formData.append("file", objFile);

 http
  .post(`report/importSales?periodId=${selectedPeriod.value.id}`, formData)
  .then((response) => {
   sucessAlert();
   emit("reportSentSuccessfully", response.data, selectedPeriod);
  })
  .catch((err) => {
   console.log(err);
   if (err.response.status === 400) {
    errorAlert(err.response.data);
    file.value = null;
   } else {
    errorAlert(
     "Certifique-se que o arquivo enviado está no padrão fornecido pela plataforma."
    );
   }
  })
  .finally(() => {
   loadingImportSales.value = false;
  });
};

const swal = inject("$swal");
const sucessAlert = () => {
 swal.fire({
  toast: true,
  icon: "success",
  title: "Planilha carregada com sucesso!",
  position: "top-right",
  showConfirmButton: false,
  timer: 3500,
  timerProgressBar: true,
 });
};
const errorAlert = (msg) => {
 swal.fire({
  position: "center",
  icon: "warning",
  title: "Algo deu errado",
  text: msg,
  showConfirmButton: true,
 });
};
</script>

<template>
 <transition enter-active-class="animated zoomIn">
  <div v-show="loading" class="flex flex-center my-3">
   <div class="loading"></div>
  </div>
 </transition>
 <Card v-show="!loading" class="animated zoomIn" style="animation-delay: 500ms">
  <div class="card-header">
   <img class="mobile-hidden" src="@/assets/images/report.svg" alt="Envios" />
   <div class="title">Novo relatório de vendas</div>
   <a
    :href="defaultSheet"
    target="_blank"
    download="Planilha_Modelo.xlsx"
    class="btn icon border tablet5 mobile12 mt-0 ml-auto"
   >
    <span>Baixar planilha modelo</span>
    <DownloadIcon fillColor="var(--primary)" />
   </a>
  </div>

  <div class="divider mt-2"></div>

  <div class="period-holder mt-2">
   <div class="input-item mobile12">
    <span>Selecione o período das vendas *</span>
    <div class="input-holder">
     <select v-model="selectedPeriod">
      <option v-for="period in periods" :key="period.id" :value="period">
       {{ period.name }}
      </option>
     </select>
     <div class="unit-holder">
      <ArrowIcon fillColor="var(--dark4)" />
     </div>
    </div>
   </div>
   <div class="flex-center mb-2 mt-3">
    <div class="btn icon send-excel">
     <span v-show="!loadingImportSales"
      >Fazer envio da planilha preenchida</span
     >
     <UploadIcon :size="30" fillColor="#fff" v-show="!loadingImportSales" />
     <input
      type="file"
      ref="selectedFile"
      v-show="!loadingImportSales"
      accept=".xlsx, .xls, .csv"
      @input="setFile"
     />
     <div v-show="loadingImportSales" class="loading white"></div>
    </div>
   </div>
  </div>
 </Card>
</template>

<style lang="scss" scoped>
.card-header {
 position: relative;
 display: flex;
 justify-content: flex-start;
 align-items: center;
 gap: 50px;
 @media only screen and (max-width: 720px) {
  flex-direction: column;
  gap: 20px;
 }
 img {
  position: absolute;
  top: -65px;
  object-fit: cover;
 }
 .title {
  margin-left: 120px;
  @media only screen and (max-width: 720px) {
   margin-left: 0px;
   margin-right: auto;
  }
 }
}

.period-holder {
 position: relative;
 width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 .input-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 50%;
  span {
   font-size: 1.1em;
   font-family: fontRegular;
   color: var(--primary);
  }
  select {
   font-size: 1em;
   color: var(--primary);
   padding: 0 50px 0 15px;
  }
 }
}

.send-excel {
 input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
 }
}
</style>
