<script setup>
import authentication from "@/util/authentication";
window.addEventListener("beforeunload", () => {
  if (localStorage.keepLogin === "false") {
    authentication.logout();
  }
});
</script>

<template>
  <router-view />
</template>

<style lang="scss">
:root {
  --primary: #00884f;
  --secondary: #85be4c;
  --gray: #8d8f92;
  --light-blue: #3f8afb;
  --orange: #fb823f;
  --red1: #e42b2b;
  --red2: #f33636;
  --dark1: #050a0e;
  --dark2: #1a1a1a;
  --dark3: #383838;
  --dark4: #5a5a5a;
  --dark-gradient: linear-gradient(0deg, #011c2a, #01111a);
  --box-shadow-default: 0 30px 54px 0 rgba(35, 54, 70, 0.12);
}

.sliders {
  .slider-control {
    svg {
      font-size: 3em;
    }
  }
}
.swiper-pagination-bullet {
  background: #fff;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: var(--primary);
}

.color-primary {
  color: var(--primary) !important;
}
.color-secondary {
  color: var(--secondary) !important;
}
.color-gray {
  color: var(--gray);
}
.color-light-blue {
  color: var(--light-blue) !important;
}
.color-orange {
  color: var(--orange) !important;
}
.color-red1 {
  color: var(--red1) !important;
}
.color-red2 {
  color: var(--red2) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}
.bg-gray {
  background-color: var(--gray);
}
.bg-light-blue {
  background-color: var(--light-blue) !important;
}
.bg-orange {
  background-color: var(--orange) !important;
}
.bg-red1 {
  background-color: var(--red1) !important;
}
.bg-red2 {
  background-color: var(--red2) !important;
}

*:not(path) {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: fontRegular;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
a,
svg,
div,
ul,
li {
  font-size: 15px;
  color: var(--gray);
}

html,
body {
  min-height: 100vh;
  min-width: 100vw;
}

img {
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
}

ul {
  list-style: none;
}

.pointer {
  cursor: pointer;
}

.container {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  @media only screen and (max-width: 1159px) {
    width: 90%;
  }
}

.main-holder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px auto 0 auto;
  width: 100%;
  padding-bottom: 20px;
}

.title {
  width: fit-content;
  font-family: fontRegular;
  font-size: 1.2em;
  color: var(--primary);
}

.divider {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
}

.navbar-hover {
  svg,
  p {
    transition: color 0.4s ease;
  }
  &:hover svg {
    color: var(--secondary);
  }
}

.navbarActive p,
.navbarActive svg {
  color: var(--primary) !important;
}

.inputs-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2em 3em;
  align-items: center;
  margin-top: 37px;
  align-items: flex-end;
}

.input-item {
  position: relative;
  width: 100%;
  span {
    color: var(--gray);
    font-family: fontLight;
  }
  .input-holder {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    border-radius: 0.6em;
    margin-top: 3px;
    input,
    select {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0 50px 0 15px;
      font-family: fontRegular;
      font-size: 1.1em;
      background-color: transparent;
      appearance: none;
    }
    .unit-holder {
      position: absolute;
      width: 50px;
      height: 100%;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      pointer-events: none;
      &.plus-min {
        width: 60px !important;
      }
      &.plus-max {
        width: 80px !important;
      }
      .line {
        position: absolute;
        width: 1px;
        height: 60%;
        background-color: var(--gray);
        left: 1px;
      }
      span {
        color: var(--gray);
      }
    }
  }
}

.flex-center {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flex-end {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.loading {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading.black {
  border: 2px solid #000;
  border-right-color: transparent;
}

.loading.white {
  border: 2px solid #fff;
  border-right-color: transparent;
}
.loading.light-blue {
  border: 2px solid var(--light-blue);
  border-right-color: transparent;
}

.bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.full-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  p {
    color: #f1f1f1;
  }
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn {
  position: relative;
  padding: 16px 22px;
  border-radius: 0.8em;
  background-color: var(--primary);
  margin: 25px auto 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  transition: transform 0.25s ease;
  &:hover {
    transform: translateY(-3px);
  }

  span {
    font-family: fontRegular;
    font-size: 1.1em;
    color: #fff;
    display: flex;
    align-items: center;
  }

  &.border {
    padding: 16px 22px;
    border: 1px solid var(--primary);
    background-color: transparent;
    box-shadow: none;
    span {
      color: var(--primary);
    }
  }

  &.inverse {
    padding: 16px 22px;
    background-color: transparent;
    box-shadow: none;
    span {
      color: var(--primary);
    }
  }

  &.icon {
    padding: 14px 20px;
    span:first-child {
      margin-right: 20px;
    }
  }
}
</style>
