import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login";
import FirstAcess from "../views/FirstAcess";
import PasswordRecovery from "../views/PasswordRecovery";
import Home from "../views/Home";
import Profile from "../views/Profile";
import Report from "../views/Report";

const routes = [
  {
    path: "/",
    alias: ["/login"],
    name: "Login",
    component: Login,
  },
  {
    path: "/primeiro-acesso/:id",
    name: "FirstAcess",
    component: FirstAcess,
  },
  {
    path: "/recuperar-senha",
    name: "PasswordRecovery",
    component: PasswordRecovery,
    meta: {
      title: "Recuperar Senha",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/meus-dados",
    name: "Profile",
    component: Profile,
    meta: {
      title: "Meus dados",
    },
  },
  {
    path: "/gerenciar-report/:id",
    name: "Report",
    component: Report,
    meta: {
      title: "Gerenciar report",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from) {
    if (from.path != to.path) {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
