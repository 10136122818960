<script setup>
import { ref, defineProps, computed } from "vue";
import { useRouter } from "vue-router";
import authentication from "../util/authentication";

import HomeIcon from "vue-material-design-icons/HomeOutline.vue";
import UserIcon from "vue-material-design-icons/AccountOutline.vue";
import ExitIcon from "vue-material-design-icons/ExitToApp.vue";

const props = defineProps({
  passwordRecovery: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const routerName = computed(() => {
  return router.currentRoute.value.name;
});

const showMenu = ref(false);
const closeMenu = () => {
  showMenu.value = false;
};

const userName = computed(() => {
  return authentication?.getUser()?.info?.name;
});

const logout = () => {
  authentication.logout();
};
</script>

<template>
  <header class="animated fadeInDown" style="animation-delay: 200ms">
    <div class="container mt-1">
      <router-link
        v-if="!props.passwordRecovery"
        class="logo pointer"
        to="/home"
      >
        <img src="../assets/images/logo.svg" alt="Logo" />
      </router-link>

      <img v-else class="logo" src="../assets/images/logo.svg" alt="Logo" />

      <div v-show="!props.passwordRecovery" class="navbar-holder mobile-hidden">
        <span class="welcome"
          >Bem-vindo, <b>{{ userName }}</b
          >!</span
        >
        <nav class="navbar">
          <ul>
            <li>
              <router-link
                class="navbar-hover"
                to="/home"
                :class="{ navbarActive: routerName == 'Home' }"
              >
                <HomeIcon />
                <p>Início</p>
              </router-link>
            </li>
            <li>
              <router-link
                class="navbar-hover"
                to="/meus-dados"
                :class="{ navbarActive: routerName == 'Profile' }"
              >
                <UserIcon />
                <p>Meus dados</p>
              </router-link>
            </li>
            <li>
              <a @click="logout" class="pointer navbar-hover">
                <ExitIcon />
                <p>Sair</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        v-show="!props.passwordRecovery"
        @click.stop="showMenu = !showMenu"
        class="menu-button desktop-hidden tablet-hidden"
      >
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>

      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <nav
          class="navbar-mobile"
          v-show="showMenu"
          v-click-outside="closeMenu"
        >
          <ul>
            <li>
              <router-link
                to="/home"
                class="create-account navbar-hover-mobile"
                :class="{ navbarActive: routerName == 'Home' }"
              >
                <HomeIcon />
                <p>Início</p>
              </router-link>
            </li>
            <li>
              <router-link
                to="/meus-dados"
                class="create-account navbar-hover-mobile"
                :class="{ navbarActive: routerName == 'Profile' }"
              >
                <UserIcon />
                <p>Meus dados</p>
              </router-link>
            </li>
            <li>
              <a
                @click="logout"
                class="create-account pointer navbar-hover-mobile"
              >
                <ExitIcon />
                <p>Sair</p>
              </a>
            </li>
          </ul>
        </nav>
      </transition>

      <router-link v-show="props.passwordRecovery" to="/" class="back-login">
        Retornar ao login
      </router-link>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  position: relative;
  width: 100vw;
  height: 90px;
  left: 0;
  top: 0;
  z-index: 999;
  .container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border-bottom: 1px solid #f1f1f1;
    .logo {
      position: relative;
      width: 15%;
      display: flex;
      img {
        object-fit: cover;
        width: 100%;
      }
      @media only screen and (min-width: 721px) and (max-width: 1159px) {
        width: 20%;
      }
      @media only screen and (max-width: 720px) {
        width: 40%;
      }
    }
    .navbar-holder {
      position: relative;
      display: flex;
      flex-direction: column;
      width: fit-content;
      .welcome {
        margin: 0 0 10px auto;
      }
      .navbar ul {
        position: relative;
        width: 320px;
        display: flex;
        gap: 35px;
        align-items: center;
        li {
          &:hover a p {
            color: var(--secondary);
          }
          position: relative;
          a {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              display: flex;
            }
            p {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.menu-button {
  position: relative;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  bottom: 5px;
  .line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #000;
  }
}

.navbar-mobile {
  position: absolute;
  top: 42px;
  right: 36px;
  width: 200px;
  padding: 15px !important;
  border-radius: 0.7em;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  z-index: 999;
  ul li {
    position: relative;
    width: 100%;
    height: 44px;

    &:not(:last-child) {
      border-bottom: 1px solid #00000015;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-family: fontLight;
        font-size: 1.15em;
        margin-left: 15px !important;
        color: var(--gray);
      }

      svg {
        font-size: 1.3em !important;
      }
    }
  }
}

.back-login {
  position: relative;
  cursor: pointer;
  transition: color 0.4s ease;
  &:hover {
    color: var(--secondary);
  }
}
</style>
