<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import http from "@/http";
import authentication from "@/util/authentication";
import validators from "../util/validators";

import Header from "../baseComponents/Header.vue";
import EmailOutline from "vue-material-design-icons/EmailOutline.vue";

const router = useRouter();
const swal = inject("$swal");
const loading = ref(false);
const validEmail = ref(false);
const validConfirmationCode = ref(false);

const user = ref({
  email: "",
  code: null,
  newPassword: "",
  password2: "",
});

const userLogin = ref({
  email: "",
  password: "",
});

const checkEmail = () => {
  if (loading.value) return;

  if (!validators.validateEmail(user.value.email)) {
    return fieldRequiredAlert(
      "Por favor, verifique se o e-mail digitado está correto."
    );
  }

  loading.value = true;

  http
    .post(`user/forgotPassword?email=${user.value.email}`, {})
    .then(() => {
      validEmail.value = true;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        fieldRequiredAlert("Usuário não encontrado em nosso sistema.");
      }
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};

const checkConfirmationCod = () => {
  if (loading.value) return;
  loading.value = true;

  http
    .post("user/forgotPassword/Validate", user.value)
    .then(() => {
      validConfirmationCode.value = true;
    })
    .catch((err) => {
      fieldRequiredAlert(
        "Parece que o código informado está incorreto. Verifique e tente novamente."
      );
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};

const changePassword = () => {
  if (loading.value) return;

  if (!user.value.newPassword) {
    return fieldRequiredAlert("Por favor, informe sua nova senha.");
  }

  if (!user.value.password2) {
    return fieldRequiredAlert("Por favor, confirme a sua nova senha.");
  }

  if (user.value.newPassword != user.value.password2) {
    return fieldRequiredAlert(
      "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente."
    );
  }

  loading.value = true;

  http
    .post("user/ResetPassword", user.value)
    .then(() => {
      userLogin.value.email = user.value.email;
      userLogin.value.password = user.value.newPassword;
      login();
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert(
        "Algo deu errado, caso o erro persista entre em contato com o suporte."
      );
      loading.value = false;
    });
};

const login = () => {
  authentication
    .login(userLogin.value)
    .then(() => {
      sucessAlert();
      router.push({ path: "/" });
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert(
        "Algo deu errado, caso o erro persista entre em contato com o suporte."
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const sucessAlert = () => {
  swal.fire({
    toast: true,
    icon: "success",
    title: "Senha alterada com sucesso!",
    position: "top-right",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
  });
};
const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    title: "Ops...",
    text: msg,
    showConfirmButton: true,
  });
};
</script>

<template>
  <div>
    <Header :passwordRecovery="true" />
    <div>
      <div
        class="main-holder desktop4 tablet6 animated fadeIn"
        style="animation-delay: 700ms"
        v-if="!validEmail"
      >
        <div class="title">Resgatar minha senha</div>

        <div class="inputs-holder mobile10">
          <div class="input-item">
            <EmailOutline :size="24" />
            <div class="input-holder">
              <input
                v-model="user.email"
                type="text"
                placeholder="Nos informe seu e-mail"
                @keypress.enter="checkEmail"
              />
            </div>
          </div>
        </div>

        <div class="mobile6 tablet6 desktop4 mt-2">
          <div @click="checkEmail" class="btn">
            <span v-show="!loading">Avançar</span>
            <div v-show="loading" class="loading white"></div>
          </div>
        </div>
      </div>

      <div
        class="main-holder desktop4 tablet6"
        v-show="validEmail && !validConfirmationCode"
      >
        <div class="title animated fadeInDown" style="animation-delay: 300ms">
          Enviamos um código para o seu e-mail
        </div>

        <div class="inputs-holder mobile10">
          <div
            class="input-item animated fadeInDown"
            style="animation-delay: 500ms"
          >
            <EmailOutline :size="24" />
            <div class="input-holder">
              <input
                v-model="user.code"
                type="text"
                placeholder="Insira o código aqui"
                @keypress.enter="checkConfirmationCod"
              />
            </div>
          </div>
        </div>

        <div
          class="animated fadeInDown mobile6 tablet6 desktop4 mt-2"
          style="animation-delay: 700ms"
        >
          <div @click="checkConfirmationCod" class="btn">
            <span v-show="!loading">Avançar</span>
            <div v-show="loading" class="loading white"></div>
          </div>
        </div>
      </div>

      <div class="main-holder desktop4 tablet6" v-show="validConfirmationCode">
        <div class="title animated fadeInDown" style="animation-delay: 300ms">
          Redefinir minha senha
        </div>

        <div class="inputs-holder">
          <div
            class="input-item animated fadeInDown"
            style="animation-delay: 500ms"
          >
            <EmailOutline :size="24" />
            <div class="input-holder">
              <input
                v-model="user.newPassword"
                type="password"
                placeholder="Nova senha"
              />
            </div>
          </div>
        </div>
        <div class="inputs-holder">
          <div
            class="input-item animated fadeInDown"
            style="animation-delay: 700ms"
          >
            <EmailOutline :size="24" />
            <div class="input-holder">
              <input
                v-model="user.password2"
                type="password"
                placeholder="Confirme a sua nova senha"
                @keypress.enter="changePassword"
              />
            </div>
          </div>
        </div>

        <div class="flex mt-2">
          <div class="animated zoomIn" style="animation-delay: 900ms">
            <div @click="changePassword" class="btn">
              <span v-show="!loading">Atualizar senha</span>
              <div v-show="loading" class="loading white"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-holder {
  background-color: #fff;
  padding: 2em 3em;
  border-radius: 1.2em;
  box-shadow: var(--box-shadow-default);
  @media only screen and (max-width: 720px) {
    background-color: transparent;
    box-shadow: none;
  }
}

.title {
  position: relative;
  width: fit-content;
  font-size: 1.3em;
  color: var(--primary);
}

.input-item {
  position: relative;
  input {
    padding: 0 50px 0 50px !important;
  }
  span {
    position: absolute;
    bottom: 4px;
    left: 15px;
    z-index: 2;
  }
}
</style>
