<script setup>
/* eslint-disable */
import { ref } from "vue";
import http from "@/http";
import { BarChart, DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import Card from "@/baseComponents/Card.vue";
import ArrowIcon from "vue-material-design-icons/ChevronDown";
import FileIcon from "vue-material-design-icons/FileExportOutline";

Chart.register(...registerables);

const apiBarCharts = ref([]);
const apiPieCharts = ref([]);
const apiEvolutionChart = ref([]);
const filters = ref([]);
const selectedFilter = ref(null);
const loading = ref(true);
const exportingToExcel = ref(false);
const showDashboard = ref(false);

http
 .get("period/listFiltersDashboard?orderBy=name&take=-1&status=Aprovado")
 .then((response) => {
  filters.value = response?.data;
  selectedFilter.value = filters.value[0];
 })
 .catch((err) => {
  console.log(err);
 })
 .finally(() => {
  loadDashboard();
 });

const loadDashboard = () => {
 http
  .get(`report/getDashboard?periodIdOrYear=${selectedFilter.value.value}`)
  .then((response) => {
   apiBarCharts.value = response.data.barCharts;
   apiPieCharts.value = response.data.pieCharts;
   apiEvolutionChart.value = response.data.evolutionChart;
   showDashboard.value = response.data.showDashboard;
  })
  .catch((err) => {
   console.log(err);
  })
  .finally(() => {
   loading.value = false;
  });
};

const exportDashboardData = () => {
 exportingToExcel.value = true;
 const headers = {
  responseType: "arraybuffer",
 };
 http
  .get(
   `report/exportSalesByUser/excel?periodIdOrYear=${selectedFilter.value?.value}`,
   headers
  )
  .then((response) => {
   downloadFile(response.data, `${selectedFilter.value?.label}.xlsx`);
  })
  .catch((err) => {
   console.log(err);
   exportingToExcel.value = false;
  });
};

const downloadFile = (arrayBuffer, fileName) => {
 const data = new Uint8Array(arrayBuffer);
 const blob = new Blob([data], {
  type:
   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
 });
 const link = document.createElement("a");
 link.href = window.URL.createObjectURL(blob);
 link.download = fileName;
 link.click();
 link.remove();
 exportingToExcel.value = false;
};

const barChartData = (obj) => {
 const marketPercentage = [];
 for (let i = 0; i < obj?.companyDataPercentage.length; i++) {
  marketPercentage.push(100);
 }
 return {
  labels: obj?.labels,
  datasets: [
   {
    label: "Total ANPII",
    data: marketPercentage,
    borderWidth: 0,
    borderRadius: 8,
    borderColor: "#7F7F7F",
    backgroundColor: "#7F7F7F",
   },
   {
    label: "Sua empresa",
    data: obj?.companyDataPercentage,
    borderWidth: 0,
    borderRadius: 8,
    borderColor: "#00874F",
    backgroundColor: "#00874F",
   },
  ],
 };
};

const evolutionChartData = (obj) => {
 return {
  labels: obj?.labels,
  datasets: [
   {
    label: "Total ANPII",
    data: obj.marketData,
    borderWidth: 0,
    borderRadius: 8,
    borderColor: "#7F7F7F",
    backgroundColor: "#7F7F7F",
   },
   {
    label: "Sua empresa",
    data: obj?.companyData,
    borderWidth: 0,
    borderRadius: 8,
    borderColor: "#00874F",
    backgroundColor: "#00874F",
   },
  ],
 };
};

const pieChartData = (obj, type) => {
 const anpiiChartColors =
  obj.marketData?.length > 6
   ? [
      "hsl(0deg 0% 10%)",
      "hsl(0deg 0% 18%)",
      "hsl(0deg 0% 26%)",
      "hsl(0deg 0% 34%)",
      "hsl(0deg 0% 42%)",
      "hsl(0deg 0% 50%)",
      "hsl(0deg 0% 58%)",
      "hsl(0deg 0% 66%)",
      "hsl(0deg 0% 74%)",
      "hsl(0deg 0% 82%)",
      "hsl(0deg 0% 90%)",
      "hsl(0deg 0% 97%)",
     ]
   : [
      "hsl(0deg 0% 16%)",
      "hsl(0deg 0% 32%)",
      "hsl(0deg 0% 48%)",
      "hsl(0deg 0% 62%)",
      "hsl(0deg 0% 78%)",
      "hsl(0deg 0% 94%)",
     ];
 const companyChartColors =
  obj.companyData?.length > 4
   ? [
      "rgb(00, 24, 14)",
      "rgb(00, 45, 26)",
      "rgb(00, 65, 38)",
      "rgb(00, 85, 50)",
      "rgb(00, 105, 62)",
      "rgb(00, 125, 74)",
      "rgb(21, 146, 94)",
      "rgb(61, 168, 124)",
      "rgb(101, 190, 153)",
      "rgb(141, 212, 183)",
      "rgb(181, 234, 212)",
      "rgb(221, 255, 241)",
     ]
   : [
      "rgb(00, 24, 14)",
      "rgb(00, 125, 74)",
      "rgb(101, 190, 153)",
      "rgb(221, 255, 241)",
     ];
 return {
  labels: obj?.labels,
  datasets: [
   {
    data: type === "anpii" ? obj.marketData : obj.companyData,
    backgroundColor: type === "anpii" ? anpiiChartColors : companyChartColors,
   },
  ],
 };
};

const options = (obj, type, chartType) => {
 return {
  maintainAspectRatio: false,
  locale: "pt-br",
  hoverOffset: 20,
  offset: 0,
  scales: {
   x: {
    grid: {
     display: chartType === "bar",
     drawBorder: false,
    },
    ticks: {
     display: chartType === "bar",
    },
   },
   y: {
    grid: {
     display: chartType === "bar",
     drawBorder: false,
    },
    ticks: {
     display: chartType === "bar",
     stepSize: 20,
    },
   },
  },
  plugins: {
   title: {
    display: true,
    text: type === "anpii" ? obj.anpiiTitle : obj?.companyTitle,
   },
   legend: {
    position: "bottom",
    labels: {
     boxWidth: 12,
    },
   },
   tooltip: {
    callbacks: {
     label: function (context) {
      if (chartType === "pie") {
       const percentage = Number(
        context?.formattedValue?.replace(",", ".")
       ).toFixed(1);
       const formattedLabel = context.label.replace("(%)", `${percentage}%`);
       return formattedLabel;
      }

      let label = context.dataset.label || "";

      if (label) {
       label += ": ";
      }

      if (context.datasetIndex === 0) {
       // if anpii-market dataset
       label += new Intl.NumberFormat("pt-BR").format(
        Number(obj.marketData[context.dataIndex]).toFixed(0)
       );
       label += " - 100%";
      } else {
       label += new Intl.NumberFormat("pt-BR").format(
        Number(obj.companyData[context.dataIndex]).toFixed(0)
       );
       label += " - ";
       const companyDataPercentage = obj?.companyDataPercentage
        ? obj?.companyDataPercentage[context.dataIndex]
        : null;
       if (companyDataPercentage) {
        const companyPercentage = Number(
         obj?.companyDataPercentage[context.dataIndex]
        ).toFixed(1);
        label += companyPercentage + "%";
       }
      }

      return label;
     },
    },
   },
  },
 };
};
</script>

<template>
 <Card v-show="showDashboard">
  <div class="card-header">
   <img
    class="mobile-hidden"
    src="@/assets/images/dashboard.svg"
    alt="Envios"
   />

   <div class="title">Dashboard</div>

   <div
    v-if="selectedFilter?.value"
    class="input-item ml-auto mt-0 mr-0"
    style="width: initial"
   >
    <span>Período/Ano</span>
    <div class="input-holder">
     <select @change="loadDashboard" v-model="selectedFilter">
      <option v-for="filter in filters" :key="filter.value" :value="filter">
       {{ filter.label }}
      </option>
     </select>
     <div class="unit-holder">
      <ArrowIcon fillColor="var(--dark4)" />
     </div>
    </div>
   </div>

   <div
    v-if="selectedFilter?.value"
    @click="exportDashboardData"
    class="btn icon border mt-0 mr-0 ml-1-desktop"
    style="top: 6px"
   >
    <span v-show="!exportingToExcel">Exportar</span>
    <FileIcon v-show="!exportingToExcel" fillColor="var(--primary)" />
    <div v-show="exportingToExcel" class="loading"></div>
   </div>
  </div>

  <div class="divider mt-2"></div>

  <div v-show="loading" class="flex-center my-3">
   <div class="loading"></div>
  </div>

  <div class="dashboard-holder">
   <div class="bar-charts">
    <BarChart
     v-for="(chartDataObj, index) in apiBarCharts"
     :key="`barChart${index}`"
     :chartData="barChartData(chartDataObj)"
     :options="options(chartDataObj, 'company', 'bar')"
    />
   </div>
   <div
    v-for="(chartDataObj, index) in apiPieCharts"
    :key="`pieChart${index}`"
    class="pie-charts"
   >
    <DoughnutChart
     :chartData="pieChartData(chartDataObj, 'anpii')"
     :options="options(chartDataObj, 'anpii', 'pie')"
    />
    <DoughnutChart
     :chartData="pieChartData(chartDataObj, 'company')"
     :options="options(chartDataObj, 'company', 'pie')"
    />
   </div>
  </div>
  <div class="bar-charts mt-2">
   <BarChart
    :chartData="evolutionChartData(apiEvolutionChart)"
    :options="{
     ...options(apiEvolutionChart, 'company', 'bar'),
     ...{ scales: { y: { ticks: { stepSize: null } } } },
    }"
   />
  </div>
 </Card>
</template>

<style lang="scss" scoped>
.card-header {
 position: relative;
 display: flex;
 justify-content: flex-start;
 align-items: center;
 gap: 50px;
 @media only screen and (max-width: 720px) {
  flex-direction: column;
  gap: 30px;
 }
 img {
  position: absolute;
  top: -65px;
  object-fit: cover;
 }
 .title {
  margin-left: 140px;
  @media only screen and (max-width: 720px) {
   margin-left: 0px;
   margin-right: auto;
  }
 }
}
.dashboard-holder {
 position: relative;
 width: 100%;
 .bar-charts {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 3rem;
 }
 .pie-charts {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 3rem;
 }
}

@media only screen and (min-width: 721px) and (max-width: 1159px) {
 .dashboard-holder {
  .bar-charts {
   grid-template-columns: 1fr;
   gap: 3rem;
  }
  .pie-charts {
   grid-template-columns: 1fr 1fr;
   gap: 2rem;
  }
 }
}

@media only screen and (max-width: 720px) {
 .dashboard-holder {
  .bar-charts {
   grid-template-columns: 1fr;
   gap: 2rem;
  }
  .pie-charts {
   grid-template-columns: 1fr;
   gap: 1.5rem;
  }
 }
}
</style>
