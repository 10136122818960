import http from "@/http";
import router from "@/router";

export default {
	login(objLogin) {
		return new Promise(function (resolve, reject) {
			http
				.post("user/login", objLogin)
				.then((response) => {
					if (response.status == 200) {
						localStorage.emailValidationCode = response.data.email;
						resolve(true);
					} else {
						resolve(false);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	logout() {
		localStorage.removeItem("user");
		localStorage.removeItem("keepLogin");
		router.push("/login");
	},
	isLoggedIn() {
		if (localStorage.user === undefined) return false;
		const user = JSON.parse(localStorage.user);
		if (!user.accessToken) return false;
		if (new Date() > new Date(user.expiration)) return false;

		return true;
	},
	getUser() {
		if (localStorage.user) {
			const user = JSON.parse(localStorage.user);
			return user;
		} else {
			return null;
		}
	},
	getUserType() {
		if (localStorage.user) {
			try {
				const user = JSON.parse(localStorage.user);
				return user?.info?.type;
			} catch (error) {
				console.log(error);
				return null;
			}
		} else {
			return null;
		}
	},
	async getRefreshedToken() {
		return new Promise((resolve) => {
			const user = localStorage.user ? JSON.parse(localStorage?.user) : null;
			if (
				router?.currentRoute?.value?.name !== "Login" &&
				router?.currentRoute?.value?.name !== "FirstAcess" &&
				user?.expiration &&
				new Date(user.expiration) < new Date() &&
				!sessionStorage.refreshingToken
			) {
				sessionStorage.refreshingToken = true;
				http
					.post(`user/refresh?token=${user.accessToken}`, "")
					.then((response) => {
						if (response?.data?.accessToken) {
							user.accessToken = response.data.accessToken;
							user.expiration = response.data.expiration;
							localStorage.user = JSON.stringify(user);
							resolve(response.data.accessToken);
						}
					})
					.catch(() => {
						this.logout();
					})
					.finally(() => {
						sessionStorage.removeItem("refreshingToken");
					});
			} else {
				resolve(user?.accessToken);
			}
		});
	},
};
