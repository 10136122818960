<script setup>
import { ref, onMounted } from "vue";
import http from "@/http";

import Header from "../baseComponents/Header";
import Footer from "../baseComponents/Footer";
import Reports from "../components/home/Reports";
import Dashboard from "../components/home/Dashboard";

import "swiper/css";
import "swiper/css/bundle";
import Swiper, { Pagination, Autoplay } from "swiper";
Swiper.use([Pagination, Autoplay]);

const loading = ref(false);

const banners = ref([]);

onMounted(() => {
  getBanners();
});

const getBanners = () => {
  http
    .get("banner/list?orderBy=-order")
    .then((response) => {
      banners.value = response?.data?.list;
      initSlider();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};

const initSlider = () => {
  setTimeout(() => {
    const slider = new Swiper("#bannerSlider", {
      autoplay: { delay: "5000" },
      speed: 500,
      autoHeight: true,
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: "true",
      },
    });
  }, 150);
};

const rightBanner = (bannerObj) => {
  return window.innerWidth > 721 ? bannerObj.image : bannerObj.mobileImage;
};
</script>

<template>
  <div>
    <Header />

    <transition enter-active-class="animated zoomIn">
      <div v-show="loading" class="full-loading">
        <div class="loading"></div>
      </div>
    </transition>

    <section class="intro">
      <div
        v-show="!loading && banners.length > 0"
        id="bannerSlider"
        class="swiper-container overflow-initial"
      >
        <div class="swiper-wrapper">
          <div
            v-for="banner in banners"
            :key="banner.id"
            class="intro-item swiper-slide"
          >
            <picture>
              <source
                media="(max-width:720px)"
                :srcset="banner.imageMobile"
                alt="imagem banner"
              />
              <source
                media="(min-width:721px)"
                :srcset="banner.image"
                alt="imagem banner"
              />
              <img
                :src="rightBanner(banner)"
                alt="imagem banner"
                loading="lazy"
              />
            </picture>
          </div>
        </div>

        <div id="bannerPagination" class="swiper-pagination"></div>
      </div>
    </section>

    <div
      v-show="!loading"
      class="container reports animated fadeInLeft"
      style="animation-delay: 150ms"
    >
      <Reports />
    </div>

    <div
      v-show="!loading"
      class="container dashboard animated fadeInRight"
      style="animation-delay: 300ms"
    >
      <Dashboard />
    </div>

    <Footer v-show="!loading" />
  </div>
</template>

<style lang="scss" scoped>
section.intro {
  position: relative;
  padding: 0 !important;
}

.intro-item {
  position: relative;
  width: 100vw;
  overflow: hidden;
  border-radius: 0 !important;
  img {
    position: relative;
    width: 100%;
  }
}

.container {
  &.reports {
    margin-top: -50px;
    z-index: 99;
    @media only screen and (max-width: 720px) {
      top: -50px;
    }
  }
  &.dashboard {
    bottom: -60px;
    z-index: 99;
    @media only screen and (max-width: 720px) {
      bottom: -50px;
    }
    .card {
      min-height: 380px;
      @media only screen and (max-width: 720px) {
        padding: 2em 1em !important;
      }
    }
  }
}

.swiper-slide {
  position: relative;
  display: block;
}

.swiper-pagination {
  transform: translateY(-50px);
  @media only screen and (max-width: 720px) {
    transform: translateY(-30px);
  }
}

.swiper-pagination-bullet {
  background: #fff !important;
  opacity: none !important;
}

.overflow-initial {
  overflow: initial !important;
}
</style>
