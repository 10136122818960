<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

import http from "@/http";
import Header from "../baseComponents/Header";
import Footer from "../baseComponents/Footer";
import Period from "../components/report/Period";
import Sale from "../components/report/Sale";

const swal = inject("$swal");

const router = useRouter();
const loading = ref(false);
const report = ref({});
const canEdit = ref(false);
const showComponentSale = ref(false);
const selectedPeriodName = ref("");
const reportId = router?.currentRoute?.value?.params?.id || "novo";
const sendingReport = ref(false);

const getReport = () => {
  loading.value = true;
  http
    .get(`report/details?id=${reportId}`)
    .then((response) => {
      showSale(response.data, { value: response.data?.period });
    })
    .catch((err) => {
      console.log(err);
      errorAlert();
    })
    .finally(() => {
      loading.value = false;
    });
};

if (reportId != "novo") getReport();

const showSale = (reportValue, selectedPeriod) => {
  if (reportId === "novo" || reportValue.status === "Alteração necessária") {
    canEdit.value = true;
  }
  if (reportValue.status === "Em análise 5P2R")
    reportValue.status = "Em análise";
  if (selectedPeriod?.value?.name)
    selectedPeriodName.value = selectedPeriod.value.name;
  report.value = reportValue;
  showComponentSale.value = true;
};

const sendReport = (report) => {
  sendingReport.value = true;
  const method = reportId === "novo" ? "post" : "put";
  http[method]("report", report)
    .then(() => {
      swal.fire({
        toast: true,
        icon: "success",
        title: "Obrigado pelo envio!",
        position: "top-right",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
      router.push("/home");
    })
    .catch((err) => {
      console.log(err);
      swal.fire({
        position: "center",
        icon: "warning",
        title: "Algo deu errado",
        text: "Por favor, verifique se todos os campos estão preenchidos corretamente.",
        showConfirmButton: true,
      });
    })
    .finally(() => {
      sendingReport.value = false;
    });
};

const errorAlert = () => {
  swal.fire({
    position: "center",
    icon: "warning",
    title: "Ops...",
    text: "Algo deu errado, tente novamente em instantes.",
    showConfirmButton: true,
  });
};
</script>

<template>
  <div class="all">
    <Header />

    <!-- <transition enter-active-class="animated zoomIn">
      <div v-show="loading" class="loading"></div>
    </transition> -->

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
    </transition>
    <div v-show="sendingReport" class="full-loading">
      <div class="loading"></div>
      <p>Por favor, aguarde.</p>
    </div>

    <div
      v-if="!showComponentSale && !loading"
      class="container mt-3 mt-1-mobile mb-3 mb-1-mobile"
    >
      <Period @reportSentSuccessfully="showSale" />
    </div>

    <div
      v-if="showComponentSale && !loading"
      class="container mt-3 mt-1-mobile mb-3 mb-1-mobile"
    >
      <Sale
        :report="report"
        :periodName="selectedPeriodName"
        :canEdit="canEdit"
        @sendReport="sendReport"
      />
    </div>

    <Footer class="footer animated fadeInUp" style="animation-delay: 700ms" />
  </div>
</template>

<style lang="scss" scoped>
.all {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.footer {
  padding: 40px 0 0 0;
}
</style>
